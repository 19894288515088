import { combineReducers, configureStore } from '@reduxjs/toolkit'
// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

import appSlice from './modules/app'
import modalSlice from './modules/modal'
import { isClientSide } from '~/helpers/common'

// const appPersistConfig = {
//   key: 'app',
//   storage: storage,
//   blacklist: [
//     'context',
//     'contextInitialized',
//     'newUserModal',
//     'dataRequesting',
//   ],
// }

const rootReducers = combineReducers({
  app: appSlice,
  modal: modalSlice
})

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: [],
// }

// const persistedReducer = persistReducer(persistConfig, rootReducers)

const store = configureStore({
  reducer: rootReducers,
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

if (process.env.NODE_ENV === 'development' && isClientSide()) {
  ; (window as any).store = store
}

export default store
