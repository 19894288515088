import { modals } from '~/constants/modals'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ModalState = {
  openedName?: ValueOf<typeof modals>
  closingName?: ValueOf<typeof modals>
}

const initialState: ModalState = {
  openedName: undefined,
  closingName: undefined,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (
      state: ModalState,
      action: PayloadAction<ValueOf<typeof modals>>,
    ) => {
      if (state.closingName === action.payload) {
        state.closingName = undefined
      }
      state.openedName = action.payload
    },
    clear: (state: ModalState) => {
      state.closingName = undefined
    },
    close: (state: ModalState) => {
      state.closingName = state.openedName
      state.openedName = undefined
    },
  },
})

export const actions = {
  ...modalSlice.actions,
}

export const { open, close, clear } = modalSlice.actions
export default modalSlice.reducer
