import { Navigation } from '@components/navigation/Navigation'
import { useDispatch } from 'react-redux'
import { setMobileMenuOpen } from 'store/modules/app'
import { useEffect } from 'react'
import { useDarkMode } from '@hooks/useDarkMode'
import NProgress from 'nprogress'
import Router, { useRouter } from 'next/router'
import { useAppSelector } from '~/store/hooks'
import { selectors } from '~/store/selectors'
import { SCROLL_DISABLED_CLASSES, THEME_TYPES } from '~/constants/common'

// Top progressbar
NProgress.configure({
  showSpinner: false,
})
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

export const NavigationContainer = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { mobileMenuOpen, isTablet, isMobile } = useAppSelector(
    (state) => state.app,
  )
  const { setTheme, colorTheme } = useDarkMode()

  const onMenuButtonClick = () => dispatch(setMobileMenuOpen(!mobileMenuOpen))

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(setMobileMenuOpen(false))
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [dispatch, router.events])

  useEffect(() => {
    // Block scrolling when menu open
    const root = document.documentElement

    if (mobileMenuOpen) {
      root.classList.add(SCROLL_DISABLED_CLASSES.SCROLL_DISABLED_MENU)
    } else {
      root.classList.remove(SCROLL_DISABLED_CLASSES.SCROLL_DISABLED_MENU)
    }

    return () => {
      root.classList.remove(SCROLL_DISABLED_CLASSES.SCROLL_DISABLED_MENU)
    }
  }, [mobileMenuOpen])

  return (
    <Navigation
      toggleTheme={setTheme}
      isMobile={isTablet || isMobile}
      isDark={colorTheme === THEME_TYPES.DARK}
      showBanner={false}
      onBannerClick={() => { }}
      {...{ onMenuButtonClick, mobileMenuOpen }}
    />
  )
}
