export const SCROLL_DISABLED_CLASSES = {
  SCROLL_DISABLED_MODAL: 'scroll-disabled-modal',
  SCROLL_DISABLED_MENU: 'scroll-disabled-menu',
}

export const SEARCH_DELAY = 300

export const PAGINATION_REQUEST_LIMIT_DEFAULT = 20
export const PAGINATION_REQUEST_LIMIT_SMALL = 5

export const THEME_CHANGE_CLASS = 'theme-change'

export const THEME_TYPES = {
  DARK: 'dark',
  LIGHT: 'light',
} as const

export const BREAKPOINTS: any = {
  SM_MAX: 767,
  MD_MIN: 768,
  MD_MAX: 1023,
  LG_MIN: 1024,
}

export enum EnvTypes {
  Development = 'development',
  Production = 'production',
}

export enum ActionStatus {
  pending = 'pending',
  available = 'available',
  busy = 'busy',
  invalid = 'invalid',
  none = 'none',
}

export const statuses = {
  idle: 'idle',
  loading: 'loading',
  succeeded: 'succeeded',
  failed: 'failed',
}

export const orderBy = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const
