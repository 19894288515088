import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { BREAKPOINTS } from '~/constants/common'
import { readThemeFromStorage } from '~/helpers/theme'
import { getListBranchs, getListServiceType } from '~/services/apis/requests'
import { AppDispatch } from '~/store'
import { statuses } from '~/store/type';

const initialState = {
  isAuth: false,
  mobileMenuOpen: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  theme: readThemeFromStorage(),
  loaded: false,
  listBranchs: {
    status: statuses.idle,
    DATA: [],
    error: null
  },
  listServiceType: {
    status: statuses.idle,
    DATA: [],
    error: null
  },
  selectedBranch: null
}

export const getListBranchsAction = createAsyncThunk(
  "GET_LIST_BRANCHS",
  async (arg, { rejectWithValue }) => {
    const response = await getListBranchs();
    const DATA = _.get(response, "OBJRETURN.data", []);
    const statusCode: number = _.get(response, "RESULT.CODE", -1);

    if (statusCode === 0) {
      return { DATA, status: statuses.succeeded };
    }

    return rejectWithValue({ DATA: [], status: statuses.idle });
  }
);

export const getListServiceTypeActions = createAsyncThunk(
  "GET_LIST_SERVICE_TYPE",
  async (arg, { rejectWithValue }) => {
    const response = await getListServiceType();
    const DATA = _.get(response, "OBJRETURN.data", []);
    const statusCode: number = _.get(response, "RESULT.CODE", -1);

    if (statusCode === 0) {
      return { DATA, status: statuses.succeeded };
    }

    return rejectWithValue({ DATA: [], status: statuses.idle });
  }
);


export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMobileMenuOpen: (state, action) => {
      state.mobileMenuOpen = action.payload
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    },
    setIsTablet: (state, action) => {
      state.isTablet = action.payload
    },
    setIsDesktop: (state, action) => {
      state.isDesktop = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload
    },
  },
  extraReducers: (builder) => {
    // GET_LIST_BRANCHS
    builder.addCase(
      getListBranchsAction.fulfilled,
      (state: any, action: any) => {
        state.listBranchs = {
          DATA: action.payload?.DATA ?? [],
          status: statuses.succeeded,
          error: null
        }
      }
    );
    builder.addCase(
      getListBranchsAction.pending,
      (state: any, action: any) => {
        state.listBranchs = {
          ...state.listBranchs,
          status: statuses.loading
        }
      }
    );
    builder.addCase(
      getListBranchsAction.rejected,
      (state: any, action: any) => {
        state.listBranchs = {
          ...state.listBranchs,
          status: statuses.failed,
          error: action.payload?.error || null
        }
      }
    );
    // GET_LIST_SERVICE_TYPE
    builder.addCase(
      getListServiceTypeActions.fulfilled,
      (state: any, action: any) => {
        state.listServiceType = {
          DATA: action.payload?.DATA ?? [],
          status: statuses.succeeded,
          error: null
        }
      }
    );
    builder.addCase(
      getListServiceTypeActions.pending,
      (state: any, action: any) => {
        state.listServiceType = {
          ...state.listBranchs,
          status: statuses.loading
        }
      }
    );
    builder.addCase(
      getListServiceTypeActions.rejected,
      (state: any, action: any) => {
        state.listServiceType = {
          ...state.listBranchs,
          status: statuses.failed,
          error: action.payload?.error || null
        }
      }
    );
  },
})

export const actions = {
  ...appSlice.actions,
}

export const {
  setMobileMenuOpen,
  setIsDesktop,
  setIsTablet,
  setIsMobile,
  setTheme,
  setLoaded,
  setSelectedBranch
} = appSlice.actions

export const setWindowState = (dispatch: AppDispatch) => {
  const sm = window.matchMedia(`(max-width: ${BREAKPOINTS.SM_MAX}px)`)
  const md = window.matchMedia(
    `(min-width: ${BREAKPOINTS.MD_MIN}px) and (max-width: ${BREAKPOINTS.MD_MAX}px)`,
  )
  const lg = window.matchMedia(`(min-width: ${BREAKPOINTS.LG_MIN}px)`)

  if (sm.matches) {
    dispatch(setIsMobile(true))
    dispatch(setIsTablet(false))
    dispatch(setIsDesktop(false))
  }

  if (md.matches) {
    dispatch(setIsMobile(false))
    dispatch(setIsTablet(true))
    dispatch(setIsDesktop(false))
  }

  if (lg.matches) {
    dispatch(setIsMobile(false))
    dispatch(setIsTablet(false))
    dispatch(setIsDesktop(true))
  }
}

export default appSlice.reducer



