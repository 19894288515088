import { useCallback, useEffect } from 'react'
import { setWindowState, setLoaded } from '../store/modules/app/index'
import { useDispatch } from 'react-redux'

export const useInitApp = () => {
  const dispatch = useDispatch()

  const onResize = useCallback(() => {
    setWindowState(dispatch)
  }, [dispatch])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setLoaded(true))
    }, 2000);
  }, [])
}
