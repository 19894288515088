import { useCallback, useEffect, useRef } from 'react'
import { THEME_CHANGE_CLASS, THEME_TYPES } from '~/constants/common'
import { setTheme } from 'store/modules/app'
import { useAppDispatch, useAppSelector } from '@store/hooks'

export const useDarkMode = () => {
  const { theme } = useAppSelector((state) => state.app)
  const dispatch = useAppDispatch()
  const timerRef = useRef<any>()

  const lastTheme =
    theme === THEME_TYPES.DARK ? THEME_TYPES.LIGHT : THEME_TYPES.DARK

  const changeTheme = useCallback(
    (variant: any) => {
      if (variant && typeof variant === 'string') {
        dispatch(setTheme(variant))

        return
      }

      if (theme === THEME_TYPES.DARK) {
        dispatch(setTheme(THEME_TYPES.LIGHT))
      } else {
        dispatch(setTheme(THEME_TYPES.DARK))
      }
    },
    [theme, dispatch],
  )

  useEffect(() => {
    const root = window.document.documentElement

    root.classList.remove(lastTheme)
    root.classList.add(theme)
    root.classList.add(THEME_CHANGE_CLASS)

    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', theme)
    }

    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      root.classList.remove(THEME_CHANGE_CLASS)
    }, 300)
  }, [theme, lastTheme])

  return { colorTheme: theme, setTheme: changeTheme }
}
