import React, { useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import Image from '@components/shared/image/Image'
import { useTranslation } from 'next-i18next'
import { MobileMenuHamburger } from './MobileMenuHamburger/MobileMenuHamburger'
import { ContentContainer } from '~/components/shared/ContentContainer/ContentContainer'
import { useRouter } from 'next/router'
import { media } from '~/helpers/media'
import { PAGE_ROUTES } from '~/constants/router'
import { NavigationItem as NavItemType } from '~/constants/navigation'
import { useDarkMode } from '~/hooks/useDarkMode'
import styles from './Navigation.module.scss'
import { NavigationItemProps } from '@components/navigation/NavigationItem/types'

const NavigationItem: React.ComponentType<NavigationItemProps> = dynamic(
  import('./NavigationItem/NavigationItem'),
)
export type NavigationProps = {
  className?: string
  isMobile: boolean
  mobileMenuOpen: boolean
  onMenuButtonClick: () => void
  toggleTheme: any
  showBanner: boolean
  onBannerClick: () => void
  isDark: boolean
}

export const Navigation = ({
  isMobile,
  mobileMenuOpen,
  onMenuButtonClick,
  toggleTheme,
  showBanner,
  onBannerClick,
  isDark,
}: NavigationProps) => {
  const { t } = useTranslation()
  const { colorTheme } = useDarkMode()
  const router = useRouter()
  const [scrollDir, setScrollDir] = React.useState('up')
  const lastScroll = useRef(0)
  const headerRef = useRef<HTMLDivElement>(null)
  const [navListItem, setNavListItem] = React.useState<NavItemType>()
  const [isOpenItem, setIsOpenItem] = React.useState(false)
  const [hasComponentVisible, setHasComponentVisible] = React.useState(false)
  const isHomePage = router.pathname === '/'

  useEffect(() => {
    if (typeof window !== 'undefined' && headerRef && headerRef.current) {
      const rect = headerRef.current.getBoundingClientRect()
      const root = document.documentElement
      const height = isMobile ? 75 : rect.height // isBanner ? 101 : 71

      root.style.setProperty('--header-height', `${height}px`)
    }
  }, [headerRef, isMobile, showBanner])

  useEffect(() => {
    setTimeout(() => {
      setScrollDir('up')
    }, 150)
  }, [router.asPath])

  useEffect(() => {
    lastScroll.current = window.scrollY
    function onScroll() {
      if (window.scrollY > 70) {
        if (window.scrollY >= lastScroll.current) {
          setScrollDir('down')
        } else {
          setScrollDir('up')
        }
      } else {
        setScrollDir('up')
      }
      lastScroll.current = window.scrollY
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    if (!headerRef.current) {
      return
    }
    if (scrollDir === 'down' && !mobileMenuOpen) {
      headerRef.current.style.transform = 'translateY(-100%)'
    } else {
      headerRef.current.style.transform = 'translateY(0%)'
    }
  }, [scrollDir, mobileMenuOpen])

  useEffect(() => {
    if (!mobileMenuOpen) {
      setNavListItem(undefined)
    }
  }, [onMenuButtonClick, mobileMenuOpen])

  return (
    <nav
      ref={headerRef}
      className={clsx(styles.header, isHomePage && styles.headerHomePage)}
    >
      {showBanner && (
        <div className={styles.banner} onClick={onBannerClick}>
          <Image
            className={styles.bannerCheck}
            src={media('images/game/iconTopBanner.svg')}
            layout="fixed"
            alt=""
            width={20}
            height={20}
          />
          <span className={styles.bannerText}>
            {t('common:whitelist.topBannerBetaWaitlist')}
          </span>
        </div>
      )}
      <ContentContainer className={styles.container}>
        <Link href={PAGE_ROUTES.HOME}>
          <span className={styles.logo}>
            <Image
              src={'images/logo-text-vib.svg'}
              alt=""
              width={100}
              height={100}
            />
          </span>
        </Link>
        {!isMobile && (
          <>
            <nav
              className={clsx(
                styles.menu,
                isOpenItem && 'button_dropdown_opened',
              )}
            >
              {[].map((item, index) => (
                <NavigationItem
                  item={item}
                  key={index}
                  setIsOpenItem={setIsOpenItem}
                  hasComponentVisible={hasComponentVisible}
                  setHasComponentVisible={setHasComponentVisible}
                />
              ))}
            </nav>
            {/* <div className={styles.rightBlock}>
              <div
                onClick={() => toggleTheme()}
                className={clsx(
                  styles.themeSwitch,
                  isHomePage && styles.themeSwitchHomePage,
                )}
              >
                <Icon
                  glyph={colorTheme === 'dark' ? 'sun' : 'moon'}
                  className={clsx(
                    styles.themeIcon,
                    isHomePage && styles.themeIconHomePage,
                  )}
                />
              </div>
            </div> */}
          </>
        )}
        {/* {isMobile && (
            <div className={styles.rightBlock}>
              <MobileMenuHamburger
                isOpen={mobileMenuOpen}
                onClick={onMenuButtonClick}
                isHomePage={isHomePage}
              />
            </div>
          )} */}
      </ContentContainer>
    </nav>
  )
}
