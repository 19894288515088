import _ from 'lodash'
import {
  CREATE_REQUEST,
  LIST_BRANCHS,
  LIST_SERVICE_TYPE,
  LIST_DEPARTMENTS,
  LIST_RATING_CHOICES,
  CREATE_REQUEST_ZALOOA_FEEDBACK,
  GET_ZALOOA_VOUCHER,
  CHECK_VALID_CLIENTNO_AND_IS_SUBMITTED
} from '~/services/url'
import { axiosClient } from '~/services/http/baseService'

export const createRequest = (payload: FormData) => {
  return axiosClient.postForm(CREATE_REQUEST, payload)
}

export const getListBranchs = () => {
  return axiosClient.get(LIST_BRANCHS)
}

export const getListServiceType = () => {
  return axiosClient.get(LIST_SERVICE_TYPE)
}

export const getListDepartments = () => {
  return axiosClient.get(LIST_DEPARTMENTS)
}

export const getListRatingChoices = (type_choice: string) =>
  axiosClient.get(LIST_RATING_CHOICES, { params: { type_choice } })

export const createRequestZaloOAFeedback = (payload: FormData) => {
  return axiosClient.postForm(CREATE_REQUEST_ZALOOA_FEEDBACK, payload)
}

export const getZaloOAVoucher = (client_no: string, ccs: string) => {
  return axiosClient.get(GET_ZALOOA_VOUCHER, { params: { client_no, ccs } })
}

export const checkValidClientNoAndIsSubmitted = (client_no: string, type_choice: string) => {
  return axiosClient.get(CHECK_VALID_CLIENTNO_AND_IS_SUBMITTED, { params: { client_no, type_choice } })
}