import clsx from 'clsx'
import style from './ContentContainer.module.scss'

type ContentContainerProps = {
  className?: string
  subLevel?: boolean
  children?: any
}

export const ContentContainer = ({
  className,
  subLevel,
  children,
}: ContentContainerProps) => {
  return (
    <div
      className={clsx(style.container, className, subLevel && style.subLevel)}
    >
      {children}
    </div>
  )
}
