import React, { useEffect, useS } from 'react'
import clsx from 'clsx'
import style from './PageLoader.module.scss'

export const PageLoader = ({ isVisible }) => {
  const id = 'loading-container'

  useEffect(() => {
    let lottie
    if (isVisible) {
      const element = document.getElementById(id)
      import('lottie-web').then((module) => {
        lottie = module
        lottie.loadAnimation({
          container: element,
          path: '/data/loading.json',
          renderer: 'svg',
          loop: true,
          autoplay: true,
          name: 'animation',
        })
      })
    } else {
      setTimeout(() => {
        lottie?.destroy('animation')
        lottie = undefined
      }, 500)
    }

    return () => {
      lottie?.destroy('animation')
      lottie = undefined
    }
  }, [isVisible])

  return (
    <div className={clsx(style.wrapper, isVisible && style.isVisible)}>
      <div className={style.bgMask} />
      <div className={style.bg}>
        <div id={id} className={style.container} />
      </div>
    </div>
  )
}
