import axios, { Axios, AxiosRequestConfig } from 'axios'
import { getAccessToken } from "~/utils/tokenHandler";

export const API_SERVER_URL: string = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api`

const baseService = axios.create({
  baseURL: `${API_SERVER_URL}`,
  headers: {
    'Accept': 'application/json',
    'Accept-Language': 'es',
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

// Add a request interceptor
baseService.interceptors.request.use(
  function (config: any) {
    const token = '';
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
baseService.interceptors.response.use(
  function (response) {
    if (response.status == 200) {
      return response.data;
    }
    return {};
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const axiosClient = axios.create({
  baseURL: `${API_SERVER_URL}`,
  timeout: 10000 * 10,
  headers: {
    'Accept': 'application/json',
    'Accept-Language': 'es',
    'Content-Type': 'application/json'
  },
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: any) {
    const token = getAccessToken();
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    if (response.status == 200) {
      return response.data;
    }
    return {};
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default baseService
