import NextImage, { ImageProps as NextImageProps } from 'next/image'
import { media } from '~/helpers/media'
import cn from 'classnames'
import { useState } from 'react'
import { useDarkMode } from '@hooks/useDarkMode'
import constants from '~/constants'

import styles from './Image.module.scss'

const loader = ({ src, width, quality }: any) => {
  return `${media(src)}?w=${width}&q=${quality || 75}`
}

export type ImageProps = NextImageProps & {
  showPlaceholder?: boolean
  roundPlaceholder?: boolean
  showPlaceholderBanner?: boolean
}

const Image = (props: ImageProps) => {
  const {
    showPlaceholder = false,
    roundPlaceholder = false,
    showPlaceholderBanner = false,
    ...restProps
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { colorTheme } = useDarkMode()
  const isDark = colorTheme === constants.common.THEME_TYPES.DARK
  const preloaderUrl = media(
    `images/placeholder/${isDark ? 'placeholder_dark.svg' : 'placeholder_light.svg'
    }`,
  )

  const handleLoadingComplete = (result: any) => {
    setIsLoading(false)

    if (props.onLoadingComplete) {
      props.onLoadingComplete(result)
    }
  }

  if (!restProps.src) {
    return null
  }

  return (
    <NextImage
      loader={loader}
      {...restProps}
      style={{
        backgroundImage:
          isLoading && showPlaceholder ? `url(${preloaderUrl})` : undefined,
        ...props.style,
      }}
      className={cn(props.className, {
        [styles.loading]: isLoading && showPlaceholder,
        [styles.round]: isLoading && roundPlaceholder,
        [styles.loadingBanner]: isLoading && showPlaceholderBanner,
      })}
      onLoadingComplete={handleLoadingComplete}
    />
  )
}

export default Image
