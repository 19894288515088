import _ from "lodash";

const ACCESS_TOKEN = "ACCESS_TOKEN";

export const setTokenInCookie = () => {
  let { cookie } = document;
  let cookies = cookie.split(";");
  for (const c of cookies) {
    let map = c.split("=");
    if (map[0].trim() === "token") {
      localStorage.setItem(ACCESS_TOKEN, map[1]);
      return map[1];
    }
  }
  return "";
};

export const getAccessToken = () => {
  let token = localStorage.getItem(ACCESS_TOKEN);
  if (!_.isEmpty(token)) {
    return token + "";
  }
  let { cookie } = document;
  let cookies = cookie.split(";");
  for (const c of cookies) {
    let map = c.split("=");
    if (map[0].trim() === "token") {
      return map[1];
    }
  }
  return "";
};

export const clearTokenLocal = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  document.cookie = "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
