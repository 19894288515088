import { Provider } from 'react-redux'
// import { persistStore } from 'redux-persist'
// import { PersistGate } from 'redux-persist/integration/react'
import store from '~/store'

// let persistor = persistStore(store)

export const withStore = (Component: any) => (props: any) => {
  return <Provider store={store}>
    <Component {...props} />
  </Provider>
}
