import 'react-toastify/dist/ReactToastify.css'
import 'tailwindcss/tailwind.css'
import 'style/.index.scss'
import dynamic from 'next/dynamic'

import { withStore } from '../HOCs/withStore'
import { NavigationContainer } from '~/containers/NavigationContainer/NavigationContainer'
import { useInitApp } from '@hooks/useInitApp'
import { PageLoader } from '~/components/shared/PageLoader/PageLoader'
import React from 'react'
import { ConfigProvider } from 'antd';

// i18n`
import { appWithTranslation } from 'next-i18next'
import { useAppSelector } from '~/store/hooks'
import type { AppProps } from 'next/app'

const RootModal = dynamic(() => import('@components/modals/RootModal'))

const ToastContainer = dynamic<any>(() =>
  import('react-toastify').then((module) => module.ToastContainer),
)

const MyApp = ({ Component, pageProps }: AppProps) => {

  const { loaded } = useAppSelector((state) => state.app)

  useInitApp()

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inter, sans-serif',
          },
        }}
      >
        <PageLoader isVisible={!loaded} />
        <Component {...pageProps} />
        {Component.displayName == "ZaloOAPage" ? '' : <NavigationContainer />}
        <ToastContainer />
        <RootModal />
      </ConfigProvider>

    </>
  )
}

export default withStore(appWithTranslation(MyApp))
